import Client from '@videoblocks/kafka-rest-client';
import { Origin } from '@videoblocks/events-ts/lib/storyblocks/common/OriginEnum';

const restProxyUrl = process.env.KAFKA_REST_PROXY_URL ?? 'localhost:8082';

const stringOrigin = process.env.KAFKA_REST_PROXY_ORIGIN ?? 'MISC';
const requestOrigin: Origin = Origin[stringOrigin];

const stringEncode = process.env.KAFKA_REST_PROXY_ENCODE ?? 'false';
const encode = stringEncode.toLowerCase() === 'true';

const referer = process.env.KAFKA_REST_REFERER ?? undefined;

const kafkaRestClient = new Client(restProxyUrl, requestOrigin, encode, referer);

export default kafkaRestClient;
