import type { GetUserResponse } from '@videoblocks/account-service-client';

import { SessionApi, UserApi } from 'account-service-client';

export default async function lookupUser(sessionToken: string | undefined): Promise<GetUserResponse | null> {
    if (!sessionToken) {
        return null;
    }

    const session = await SessionApi.getBySessionToken(sessionToken);

    if (!session || !session.userId) {
        return null;
    }

    return UserApi.getByUserId(session.userId);
}