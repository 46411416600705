import { is404Error } from './is404Error';

import { SessionsApi, type UserSession } from '@videoblocks/account-service-client';

import { accountServiceConfig } from './accountServiceConfig';

const sessionsApi = new SessionsApi(accountServiceConfig);

async function getBySessionToken(sessionToken: string): Promise<UserSession | null> {
    try {
        const response = await sessionsApi.getSession(sessionToken);
        return response.data.session ?? null;
    } catch (e) {
        if (is404Error(e)) {
            return null;
        }
        throw e;
    }
}

export const SessionApi = { getBySessionToken };
