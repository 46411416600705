import UAParser from 'ua-parser-js';
import { v4 } from 'uuid';

import { GetUserResponse } from '@videoblocks/account-service-client';
import {
    type Device,
    PageViewEvent,
    type SearchIdentifiers,
    type Geo,
} from '@videoblocks/events-ts/lib/storyblocks/navigation/PageViewEvent';
import { Origin } from '@videoblocks/events-ts/lib/storyblocks/navigation/OriginEnum';
import { constructUtmParamsForPageViewEvent, parseUtmParamsFromSearchParams } from '../UtmParams';

export type PageViewEventFactoryOptions = {
    currentUrl: URL;
    headers: Headers;
    getUserResponse: GetUserResponse | null;
    visitorCookieId: string | null;
    searchIdCookie: string | null;
    searchPageIdCookie: string | null;
    statusCode: number;
    referrer: URL | undefined;
    geo: Geo | null
};

function create(options: PageViewEventFactoryOptions): PageViewEvent {
    const {
        currentUrl,
        headers,
        getUserResponse,
        visitorCookieId,
        searchIdCookie,
        searchPageIdCookie,
        statusCode,
        referrer,
        geo,
    } = options;

    const referrerUrl = referrer?.href;
    const ipAddress = headers.get('X-Forwarded-For') ?? '';
    const clickOrigin = currentUrl.searchParams.get('click-origin') ?? '';

    const userId = getUserResponse?.user?.id ?? null;
    const organizationId = getUserResponse?.organizations?.[0]?.id ?? null;
    const subscriptionId = getUserResponse?.userSubscriptions?.[0]?.id ?? null;

    const event = new PageViewEvent();
    event.urls = { currentUrl: currentUrl.href, referrerUrl };
    event.meta = {
        uuid: v4(),
        emitted: Date.now(),
        origin: Origin.STORYBLOCKS_NEXTJS,
        trace: null, // Trace is set to null because the old trace code (StockBlocks) is for NewRelic - 2023-11-27 Clark
    };
    event.device = device(headers, referrerUrl);
    event.user = { userId, visitorCookieId, organizationId, subscriptionId };
    event.geo = geo;
    event.searchIdentifiers = searchIdentifiers(searchIdCookie, searchPageIdCookie);
    event.statusCode = statusCode;
    event.ipAddress = ipAddress;
    event.clickOrigin = clickOrigin;

    const sanitizedUtmValues = parseUtmParamsFromSearchParams(currentUrl.searchParams ?? new URLSearchParams());
    event.utmParameters = constructUtmParamsForPageViewEvent(sanitizedUtmValues);

    return event;
}

export const PageViewEventFactory = { create };

function searchIdentifiers(searchIdCookie: string | null, searchPageIdCookie: string | null): SearchIdentifiers | null {
    if (searchIdCookie && searchPageIdCookie) {
        return { searchId: searchIdCookie, searchPageId: searchPageIdCookie };
    }
    return null;
}

function device(headers: Headers, referrerUrl: string | undefined): Device {
    const language = headers.get('Accept-Language') ?? '';
    const header = headers.get('User-Agent');

    let userAgent = '',
        name = '',
        platform = '';

    if (header) {
        const uaParser = new UAParser(header);
        userAgent = uaParser.getUA();
        name = uaParser.getBrowser().name ?? '';
        platform = uaParser.getOS().name ?? '';
    }

    return { browser: { language, name, platform, userAgent }, navigation: { referrerUrl: referrerUrl ?? '' } };
}
