import { GetUserResponse, UsersApi } from '@videoblocks/account-service-client';

import { accountServiceConfig } from './accountServiceConfig';
import { is404Error } from './is404Error';

const usersApi = new UsersApi(accountServiceConfig);

async function getByUserId(userId: number): Promise<GetUserResponse | null> {
    try {
        const response = await usersApi.getUserById(userId, {
            params: { fetchOptions: { fetchOrganizations: true, fetchUserSubscriptions: true } },
        });
        return response.data;
    } catch (e) {
        if (is404Error(e)) {
            return null;
        }
        throw e;
    }
}

export const UserApi = { getByUserId };
