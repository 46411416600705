import { v4 } from 'uuid';
import { Origin } from '@videoblocks/events-ts/lib/storyblocks/navigation/OriginEnum';
import { SignupSurveyEntEvent } from '@videoblocks/events-ts/lib/storyblocks/user/SignupSurveyEntEvent';
import { GetUserResponse } from '@videoblocks/account-service-client';

export type SignupSurveyEventFactoryOptions = {
    getUserResponse: GetUserResponse | null;
    visitorCookieId: string | null;
    channel: string;
    comment: string;
};

function create(options: SignupSurveyEventFactoryOptions): SignupSurveyEntEvent {
    const {
        getUserResponse,
        visitorCookieId,
        channel,
        comment,
    } = options;

    const userId = getUserResponse?.user?.id ?? null;
    const organizationId = getUserResponse?.organizations?.[0]?.id ?? null;
    const subscriptionId = getUserResponse?.userSubscriptions?.[0]?.id ?? null;

    const event = new SignupSurveyEntEvent();
    event.meta = {
        uuid: v4(),
        emitted: Date.now(),
        origin: Origin.STORYBLOCKS_NEXTJS,
        trace: null,
    };
    event.user = { userId, visitorCookieId, organizationId, subscriptionId };
    event.channel = channel;
    event.comment = comment;

    return event;
}

export const SignupSurveyEventFactory = { create };
