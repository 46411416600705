import lookupUser from '../shared/lookupUser';

import { SignupSurveyEventFactory } from './SignupSurveyEventFactory';
import KafkaRestClient from '../KafkaRestClient';
import { type ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';

export async function publishEvent(
    cookies: ReadonlyRequestCookies,
    channel: string,
    comment: string
): Promise<void> {
    const visitorIdCookie = cookies.get('VID')?.value ?? null;
    const loginSessionToken = cookies.get('login_session')?.value;

    const getUserResponse = await lookupUser(loginSessionToken);

    const event = SignupSurveyEventFactory.create({
        visitorCookieId: visitorIdCookie,
        getUserResponse,
        channel,
        comment,
    });

    await KafkaRestClient.produce(event);
}

export const SignupSurveyEventService = { publishEvent };
